const allProducts = () => {
    const allProductsDiv = document.querySelector('.all-products');
    fetch('https://shop.crocespinelli.com/all-products.html')
        .then(res => res.text()) // parse response as JSON (can be res.text() for plain response)
        .then(response => {
            allProductsDiv.innerHTML = response;
            new ElderCarousel('.ba-simple-slider .products', {
                items: getCarouselItemsToDisplay(window.innerWidth),
                resizeCb: (carousel, w) => {
                    carousel.settings.items = getCarouselItemsToDisplay(w);
                }
            });
        })
        .catch(err => {
            allProductsDiv.parentNode.removeChild(allProductsDiv);
        });

    const getCarouselItemsToDisplay = (w) => {
        if (w <= 576) {
            return 1;
        } else if (w < 768) {
            return 2;
        } else if (w < 1024) {
            return 3;
        } else {
            return 5;
        }
    }
}