const animatedLogo = () => {

    const COOKIE_NAME = 'crocespinelli-intro';
    const body = document.querySelector('body');
    const animatedLogo = document.querySelector('#animated-logo');

    const getCookie = (name) => {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const setCookie = (name, value, options = {}) => {
        options = {
            path: '/',
            // add other defaults here if necessary
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    const loadIntro = () => {
        const enterButton = animatedLogo.querySelector('.enter');
        body.classList.add('nooverflow');

        setTimeout(() => {
            if (animatedLogo) {
                enterButton.classList.remove('noopacity');
            }

            enterButton.addEventListener('click', (e) => {
                e.preventDefault();

                setCookie(COOKIE_NAME, 'true', {'max-age': 86400})

                animatedLogo.classList.add('moveTop');
                setTimeout(() => {
                    body.classList.remove('nooverflow');
                }, 800);
            })
        }, 3000);
    }

    if (!getCookie(COOKIE_NAME)) {
        animatedLogo.classList.remove('hidden');
        // Ensure svg is loaded before displaying it
        fetch(document.location.href + 'img/animated-logo2-bw.svg')
            .then(res => res.text())
            .then(() => {
                const object = document.createElement('object');
                object.setAttribute('data', "/img/animated-logo2-bw.svg");
                animatedLogo.querySelector('#animated-inject').appendChild(object);
                window.scrollTo(0, 0);
                loadIntro();
            })

    } else {
        animatedLogo.classList.add('hidden');
    }

    body.classList.remove('noopacity');

}