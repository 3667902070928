const latestNews = () => {
    const latestNewsDiv = document.querySelector('.latest-news');
    fetch('https://saintesprit-provence.com/news.html')
        .then(res => res.text()) // parse response as JSON (can be res.text() for plain response)
        .then(response => {
            latestNewsDiv.innerHTML = response;
        })
        .catch(err => {
            latestNewsDiv.parentNode.removeChild(latestNewsDiv);
        });
}