const fixNav = () => {
    const header = document.getElementById("header");
    const main = header.nextElementSibling;
    let stuck = false;
    let stickPoint = getDistance();

    function getDistance() {
        return header.offsetTop;
    }

    window.onscroll = function (e) {
        var distance = getDistance() - window.pageYOffset;
        var offset = window.pageYOffset;
        if ((distance <= 0) && !stuck) {
            header.style.position = 'fixed';
            header.style.top = '0px';
            header.classList.add('fixed');
            main.classList.add('mtop');
            stuck = true;
        } else if (stuck && (offset <= stickPoint)) {
            header.style.position = 'static';
            stuck = false;
            header.classList.remove('fixed');
            main.classList.remove('mtop');
        }
    }
}