const newsletter = () => {
    const newsletter = document.querySelector(".block_newsletter");
    const form = newsletter.querySelector('form');
    const btnSubmit = form.querySelector('[type="submit"]');
    const divResponse = form.querySelector('.nl-response');

    form.addEventListener("submit", e => {
        e.preventDefault();

        btnSubmit.setAttribute('disabled', 'disabled');

        grecaptcha.ready(function () {
            grecaptcha.execute('6Lf52N4aAAAAAKui7Ys-wMgTW8me9lRpfGyfkZz8', {action: 'submit'}).then(function (token) {
                fetch(form.getAttribute('action'), {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        recaptcha_response: token,
                        email: form.querySelector('[name="email"]').value
                    })
                })
                    .then((response) => {
                        setTimeout(() => {
                            btnSubmit.removeAttribute('disabled');
                            divResponse.innerHTML = '';
                        }, 5000);

                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Une erreur est survenue, inscription impossible');
                        }
                    })
                    .then((response) => {
                        divResponse.classList.remove('text-danger');
                        divResponse.classList.add('text-success');
                        divResponse.innerHTML = 'Inscription réussie';
                    })
                    .catch((error) => {
                        divResponse.classList.remove('text-success');
                        divResponse.classList.add('text-danger');
                        divResponse.innerHTML = error;
                    });

            });
        });
    });
}